import React from 'react'

import Box from 'components/Box'
import Text from 'components/Text'
import Container from 'components/Container'
import { responsive } from 'components/ThemeProvider/theme';


const Title = ({ text, ...props }) => {
  return (
    <Box bg="lightPurple" pt={responsive('1.875em', '4em')} pb={responsive('1.375em', '4em')} {...props}>
      <Container>
        <Text.H1 fontSize={responsive('1.875em', '2.1875em')}>{text}</Text.H1>
      </Container>
    </Box>
  )
}

export default Title
