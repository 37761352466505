import React from 'react'

import Container from 'components/Container';
import Box from 'components/Box'
import Title from 'components/Title'
import { responsive } from 'components/ThemeProvider/theme';

const ContentLayout = ({ children, title, ...props }) => {
  return (
    <>
      <Title text={title} />
      <Box bg="lightGray" overflowX="hidden">
        <Container pt={responsive('1.25em', '5.125em')} pb={responsive('6.25em', '10.875em')} {...props}>
          {children}
        </Container>
      </Box>
    </>
  )
}

export default ContentLayout
